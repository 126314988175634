@use '@/styles/use-bootstrap' as *;
@use '@/styles/util/mixins' as *;
@use '@/styles/util/vars' as *;

$bgImgDarken: 0.35;
$border: 1px solid $borderColorGray;
$border-radius: 10px;

$contentMarginTop: 15.5vh;

$titleTextRotate: 90deg;
$titleIconOffsetX: -0.5rem;
$titleIconOffsetY: 0rem;

.slides {
  border-radius: $borderRadiusLarge;
  display: flex;
  overflow: hidden;

  flex-direction: column;
  height: calc(70vh - 70px);

  @include media-breakpoint-up(sm, $grid-breakpoints) {
    flex-direction: row;
    margin: 0 auto;
  }

  .slide {
    filter: grayscale(1);
    flex: 1;
    position: relative;
    transition: filter 0.5s ease-in-out, flex-grow 0.5s ease-in-out;

    &:nth-of-type(3) .titleWrap svg {
      font-size: 1.75rem;
      stroke-width: 0;
    }

    .titleIcon {
      font-size: 1.75rem;
    }

    .titleWrap {
      color: $lightColor;
      font-size: 1.65rem;
      font-weight: $fontWeightBold3;
      text-transform: uppercase;
      text-shadow: 0 0 2px rgba(255, 255, 255, 0.6);
      transition: opacity 0.3s;
      white-space: nowrap;
      width: 100%;

      .titleWrap1 {
        align-items: center;
        display: flex;
        left: calc($titleIconOffsetY + 2rem);
        position: absolute;
        top: $contentMarginTop;
        transition: opacity 0.3s;

        .titleIcon {
          margin-right: 0.7rem;
        }

        .text {
          transform-origin: left center;
        }
      }

      .titleWrap2 {
        opacity: 0;
        left: 45%;
        position: absolute;
        top: $contentMarginTop;
        transition: opacity 0.3s;
      }
    }

    .contentWrap {
      height: 100%;

      .content {
        align-items: center;
        color: $lightColor;
        display: flex;
        flex-direction: column;
        font-size: $fontSizeBody;
        font-weight: $fontWeightBold2;
        height: 100%;
        justify-content: flex-start;
        margin: 0;
        opacity: 0;
        text-align: left;
        text-shadow: 0 0 2px rgba(255, 255, 255, 0.4);
        transition: opacity 0.04s;

        .contentInner {
          margin: 0 auto;
          margin-top: $contentMarginTop;
          max-width: 80%;

          .title {
            align-items: flex-end;
            display: flex;
            font-size: 1.3rem;
            font-weight: 600;

            .titleIcon {
              margin-right: 0.8rem;
            }
          }

          .body::before {
            background-color: $lightColor;
            content: '';
            display: block;
            height: 1.8px;
            margin: 1.5rem auto 1.5rem 0;
            width: 99%;
          }
        }
      }
    }

    &:nth-child(1) {
      @include bgImg('/pages/business/viral/sections/about/bg_1.webp', $bgImgDarken);
    }

    &:nth-child(2) {
      @include bgImg('/pages/business/sns/sections/about/bg_1.png', $bgImgDarken);
    }

    &:nth-child(3) {
      @include bgImg('/pages/business/media/sections/about/bg_1.png', $bgImgDarken);
    }

    &:nth-child(4) {
      @include bgImg(
        '/pages/business/marketing/sections/about/heading_5.png',
        $bgImgDarken
      );
    }
  }

  &.active {
    .slide {
      .titleWrap1 {
        opacity: 0;
      }

      .titleWrap2 {
        opacity: 1;
      }

      &.active,
      &:hover {
        filter: grayscale(0.2);
        flex-grow: 4.5;

        .titleWrap2 {
          opacity: 0;
        }

        .content {
          background-color: rgba(0, 0, 0, 0.3);
          opacity: 1;
          transition: opacity 0.5s 0.4s, flex-grow 0.5s ease-in-out;

          .titleIcon {
            opacity: 1;
          }
        }
      }

      &:not(.active) {
        .titleIcon {
          opacity: 1;
        }
      }
    }
  }
}
