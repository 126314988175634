// @use 'math';
// @use '~bootstrap/scss/functions' as *;
// @use '~bootstrap/scss/variables' as *;
// @use '~bootstrap/scss/mixins/breakpoints' as *;

//
// General
//

//
// Color
//

// REF: https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=4FC3F7
// REF: https://www.color-hex.com/color-palette/71758
// Material Light Blue 300
$defaultColor: #212529;
$default2Color: #262626;
$blurredColor: #484848;
$grayColor: #666666;
$gray2Color: #919191;
$gray3Color: #ececec;
$lightColor: #ffffff;
$logoColor1: #00a1e9;
$logoColor2: #0b3190;
$logoColor3: #181c62;
$primaryColor: lighten($logoColor1, 10%);
$secondaryColor: lighten($logoColor2, 10%);
$violetColor: #d645be;
$violetColor2: #9445d6;

$highlightColor: $logoColor1;

// Background Color
// $bgPrimaryColor: #20639b;
$bgPrimaryColor: #1976d2; // Blue 700
$bgDarkColor: #333333;
$bgDarkColor2: #444444;
$bgDarkColor3: #5c636a;
$bgGrayColor: #f4f4f4;
$bgGrayColor2: #eeeeee;
$bgLightColor: #ffffff;
$bgLoadingColor: #666666;

// Footer Color
$footerBackgroundColor: $bgDarkColor;
$footerColor: $lightColor;

//
// Font
//

// Font Size
$fontSizeDefault: 1rem;
$fontSizeBodySm: calc($fontSizeDefault * 1.05);
$fontSizeBodySmMobile: calc($fontSizeDefault * 0.9);
$fontSizeBody: calc($fontSizeDefault * 1.1);
$fontSizeBodyMobile: calc($fontSizeDefault * 1);
$fontSizeBodyLg: calc($fontSizeDefault * 1.2);
$fontSizeBodyLgMobile: calc($fontSizeDefault * 1.1);
$fontSizeBodyXl: calc($fontSizeDefault * 1.35);
$fontSizeBodyXlMobile: calc($fontSizeDefault * 1.2);
$fontSizePEm: calc($fontSizeDefault * 1.2);
$fontSizePEmMobile: calc($fontSizeDefault * 1.1);
$fontSizeContentTitle: calc($fontSizeDefault * 1.8);
$fontSizeContentTitleMobile: calc($fontSizeDefault * 1.3);
$fontSizeContentTitleLg: calc($fontSizeDefault * 2.3);
$fontSizeContentTitleLgMobile: calc($fontSizeDefault * 1.7);
$fontSizeRem1p5: 1.5rem;
$fontSizeSubtitle: calc($fontSizeDefault * 1.2);
$fontSizeSubtitleMobile: calc($fontSizeDefault * 1);
$fontSizeTitle: calc($fontSizeDefault * 2.7);
$fontSizeTitleMobile: calc($fontSizeDefault * 1.8);

$fontSizeCard: $fontSizeDefault;
$fontSizeCardEM: 2.8rem;
$fontSizeLiMarker: 0.9rem;

// Font Weight
$fontWeightDefault: 400;
$fontWeightBold1: 500;
$fontWeightBold2: 600;
$fontWeightBold3: 700;
$fontWeightBold4: 800;
$fontWeightBold5: 900;

$fontWeightCardEM: $fontWeightBold2;

// line-height
$lineHeightTitle: 2;
$lineHeightBody: 1.75;

$opacityBody: 0.8;

// text-shadow
$shadow-color: 0deg 2% 65%;

$shadow-elevation-low: 0.3px 0.5px 0.7px hsl(var($shadow-color) / 0.34),
  0.4px 0.8px 1px hsl(var($shadow-color) / 0.34),
  1px 2px 2.5px hsl(var($shadow-color) / 0.34);
$shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var($shadow-color) / 0.36),
  0.8px 1.6px 2px hsl(var($shadow-color) / 0.36),
  2.1px 4.1px 5.2px hsl(var($shadow-color) / 0.36),
  5px 10px 12.6px hsl(var($shadow-color) / 0.36);
$shadow-elevation-high: 0.3px 0.5px 0.7px hsl(var($shadow-color) / 0.34),
  1.5px 2.9px 3.7px hsl(var($shadow-color) / 0.34),
  2.7px 5.4px 6.8px hsl(var($shadow-color) / 0.34),
  4.5px 8.9px 11.2px hsl(var($shadow-color) / 0.34),
  7.1px 14.3px 18px hsl(var($shadow-color) / 0.34),
  11.2px 22.3px 28.1px hsl(var($shadow-color) / 0.34),
  17px 33.9px 42.7px hsl(var($shadow-color) / 0.34),
  25px 50px 62.9px hsl(var($shadow-color) / 0.34);

//
// Border
//

$borderColorGray: $gray3Color;
$borderLightColor: $lightColor;
$borderNone: 0;
$borderRadiusDesktop: 7px;
$borderRadiusImage: 5px;
$borderRadiusLarge: 20px;
$borderRadiusMobile: 14px;

$borderGray: 1px solid $borderColorGray;

//
// Animation
//

$boxShadowDefault: 5px 5px 20px rgba(0, 0, 0, 0.4);
$transitionDefault: all 0.3s ease-out;

//
// Layout
//

$containerMaxWidth: 75rem;

// $innerContainerMaxWidth: 1800px;
$innerContainerMaxWidth: $containerMaxWidth;
$outerContainerPadding: 0 10vw;

//
// Header
//

$headerBackgroundColor: $bgLightColor;
$headerColor: $lightColor;
// $headerInnerPadding: 1.6rem 10vw;
$headerMaxWidth: $containerMaxWidth;

//
// Navbar
//

$navbarBoxShadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
$navbarCollapseItemColor: hsla(0, 0%, 100%, 0.8);
$navbarCollapseItemColorHover: hsla(0, 0%, 100%, 1);
$navbarHeight: 90px;
$navbarTopMobile: 25vh;

//
// Nav
//

$navItemColor: $default2Color;
$navItemFontSizeDesktop: $fontSizeDefault;
$navItemFontSizeMobile: $fontSizeRem1p5;
$navItemDelayAdd: 0.1s;
$navItemDelayBase: 0.3s;
$navItemFontWeightDesktop: $fontWeightDefault;
$navItemFontWeightMobile: $fontWeightDefault;
$navItemTransition: color 0.3s ease-out;

//
// Etc
//

//
// ChartLine
//

$chartLineColor: #cccccc;
$chartLineWidth: 2px;

//
// Banner
//

$bannerTitleFontSize: 2.5rem;
$bannerTitleFontWeight: $fontWeightBold2;
$bannerContentFontSize: 1.6rem;
$bannerContentFontWeight: $fontWeightBold1;

//
// VerticalLine
//

$verticalLineWidth: 3px;
$verticalLine1Border: $verticalLineWidth solid rgba(0, 161, 233, 0.6);
$verticalLine2Border: $verticalLineWidth solid rgba(33, 37, 41, 0.6);

//
// Bottom Nav
//

$bottomNavbarBoxShadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
$bottomNavItemFontSize: $fontSizeDefault;
$bottomNavItemFontWeight: $fontWeightBold1;
