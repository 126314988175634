@use '@/styles/use-bootstrap' as *;
@use '@/styles/util/mixins' as *;
@use '@/styles/util/vars' as *;

.contentSection {
  background-color: $bgLightColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  word-break: keep-all;

  padding-bottom: 3rem;
  padding-top: 5rem;

  @include media-breakpoint-up(md, $grid-breakpoints) {
    padding-bottom: 0;
    padding-top: 3rem;
  }

  &#sectionBanner {
    padding-bottom: 0;
  }

  &.bgDark {
    background-color: $bgDarkColor;
  }

  &.bgGray {
    background-color: $bgGrayColor2;
  }

  &.bgLight {
    background-color: $bgLightColor;
  }

  &.colorLight {
    color: $lightColor;

    .titleWrap {
      & > .subtitle h3 {
        color: $lightColor;
      }

      & > .title::after {
        background-color: $lightColor;
      }
    }
  }

  &.isCentered {
    text-align: center;
  }

  &.isFullHeight {
    height: 100vh;

    &:global(.container),
    :global(.container) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &.isFullWidth {
    width: 100vw;

    &:global(.container),
    :global(.container) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .contentWrap {
    @include container();

    // align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .titleWrap {
    margin-bottom: 2.5rem;
    padding: 0 1rem;
    text-align: center;

    &.titleSeparatorDefault .title::after {
      background-color: $logoColor1;
    }

    &.titleSeparatorDark .title::after {
      background-color: $defaultColor;
    }

    &.titleSeparatorNone .title::after {
      margin: 0.5rem auto;
      opacity: 0;
    }

    .title::after {
      content: '';
      display: block;
      height: 5px;
      margin: 0.8rem auto;
      width: 3rem;
    }

    .title h2 {
      font-weight: 300;

      font-size: 1.8rem;

      @include media-breakpoint-up(md, $grid-breakpoints) {
        font-size: 2rem;
      }

      em {
        font-style: normal;
        font-weight: $fontWeightBold3;
      }
    }

    .subtitle h3 {
      color: $grayColor;
      font-weight: 500;

      font-size: 0.9rem;

      @include media-breakpoint-up(md, $grid-breakpoints) {
        font-size: 0.9rem;
      }
    }
  }

  .content {
    overflow: hidden;

    &.isFullWidth {
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      .contentBody {
        max-width: initial;
      }
    }

    &.isOverflowVisible {
      overflow: visible;
    }

    @include media-breakpoint-up(md, $grid-breakpoints) {
      padding: $outerContainerPadding;
    }

    // .contentTitle {
    //   margin-bottom: 5rem;

    //   @include media-breakpoint-down(md, $grid-breakpoints) {
    //     h3 {
    //       font-size: css-clamp(1rem, 7.5vw, 1.5rem);
    //       margin-bottom: 2rem;
    //     }
    //   }
    // }

    .contentBody {
      margin: 0 auto;
      // max-width: $innerContainerMaxWidth;
    }
  }
}
