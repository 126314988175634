@use '@/styles/util/vars' as *;

@mixin container($width: 100%, $height: 100%, $padding: 0, $margin: 0) {
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
}

@mixin bgImg($url, $darken: 0, $fixed: false) {
  background: {
    image: linear-gradient(rgba(0, 0, 0, $darken), rgba(0, 0, 0, $darken)), url('#{$url}');
    position: center;
    repeat: no-repeat;
    size: cover;
  }

  @if $fixed {
    background-attachment: fixed;
  }
}

@mixin circle($radius) {
  border-radius: $radius;
  height: $radius;
  line-height: $radius;
  width: $radius;
}

@mixin chartLineHL(
  $width,
  $left,
  $top: 50%,
  $color: $chartLineColor,
  $height: $chartLineWidth
) {
  background: $color;
  content: '';
  height: $height;
  left: $left;
  position: absolute;
  top: $top;
  transform: translateY(-50%);
  width: $width;
}

@mixin chartLineHR(
  $width,
  $right,
  $top: 50%,
  $color: $chartLineColor,
  $height: $chartLineWidth
) {
  background: $color;
  content: '';
  height: $height;
  right: $right;
  position: absolute;
  top: $top;
  transform: translateY(-50%);
  width: $width;
}

@mixin chartLineV(
  $height,
  $top,
  $left: 50%,
  $color: $chartLineColor,
  $width: $chartLineWidth
) {
  background: $color;
  content: '';
  height: $height;
  left: $left;
  position: absolute;
  top: $top;
  transform: translateX(-50%);
  width: $width;
}
